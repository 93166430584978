<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import type { Route } from './NavigationTabs.vue'

defineProps({
  page: {
    type: Object as PropType<Route>,
    required: true,
  },
})

const emit = defineEmits(['closeMenu'])

function closeMenu() {
  emit('closeMenu')
}
</script>

<template>
  <a v-if="page.isLink" :href="page.route" target="_blank" class="navigation-tabs__item" @click="closeMenu">
    <span>
      <img v-if="page.icon" :src="page.icon" alt="" class="q-icon text-body1 q-mr-sm">
      {{ page.name }}
    </span>
    <q-badge v-if="page.badgeNew" color="negative" class="navigation-tabs__item__badge">
      {{ $t('common.new') }}
    </q-badge>
  </a>
  <router-link v-else :to="page.route" class="navigation-tabs__item navigation-tabs__route" @click="closeMenu">
    <span>
      <img v-if="page.icon" :src="page.icon" alt="" class="q-icon text-body1 q-mr-sm" :class="{ [page.iconColor!]: page.iconColor }">
      {{ page.name }}
    </span>
    <q-badge v-if="page.badgeNew" color="negative" class="navigation-tabs__item__badge">
      {{ $t('common.new') }}
    </q-badge>
  </router-link>
</template>
