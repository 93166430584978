<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts">
export default defineComponent({
  props: {
    tpsThreshold: {
      type: Number,
      default: 1500,
    },
  },
  setup(props) {
    const clusterStore = useClusterStore()
    const tpsLoading = computed(() => clusterStore.tpsLoading)
    const tps = computed(() => clusterStore.tps)
    const style = ref()
    const message = ref()

    const { t } = useTranslation()

    const handleTps = () => {
      let msg: string | undefined, backgroundColor: string | undefined
      console.log('[TPS]: ', tps.value)

      if (!tpsLoading.value) {
        if (tps.value <= 0) {
          msg = t('network.failure')
          backgroundColor = 'var(--q-negative)'
        } else if (tps.value < props.tpsThreshold) {
          msg = t('network.experiencing')
          backgroundColor = 'var(--q-info)'
        } else {
          msg = undefined
        }
      }

      message.value = msg
      style.value = {
        backgroundColor: backgroundColor ?? 'none',
        height: msg ? 'auto' : 0,
        padding: msg ? '5px' : 0,
      }
    }

    watch(tps, async () => {
      setTimeout(handleTps, 500)
    }, { immediate: true })

    return {
      style,
      message,
    }
  },
})
</script>

<template>
  <q-slide-transition>
    <div v-if="message" :class="$style.root" :style="style">
      {{ message }}
    </div>
  </q-slide-transition>
</template>

<style module lang="scss">
  .root {
  //position: fixed;
  position: relative;
  width: 100%;
  //bottom: -20px;
  //top: 0;
  padding: 5px;
  background: rgba($accent, 0.94);
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  z-index: 1;
  text-align: center;
}
</style>
