<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script setup lang="ts">
import { matClose, matMenu } from '@quasar/extras/material-icons'
import jpoolLogoDark from '~/assets/img/logo/jpool-logo-dark.svg'
import { PriorityFee } from '~/features/priority-fee'

const route = useRoute()
const isHoldersClub = computed(() => route.path.includes('holders-club'))

const visible = ref(false)

function handleClick(e: any) {
  const isOutsideClick = e?.srcElement?.id === 'mobile-menu-wrapper'
  if (isOutsideClick) {
    closeMenu()
  }
}

function closeMenu() {
  visible.value = false
}
</script>

<template>
  <q-btn v-bind="$attrs" dense unelevated class="q-pa-sm mobile-menu-btn" color="secondary" text-color="black" @click="visible = true">
    <q-icon size="38px" :name="matMenu" color="black" />
  </q-btn>
  <q-icon
    :name="matClose" class="app-sidebar__close" :class="{ 'app-sidebar__close--active': visible }"
    @click="visible = false"
  />
  <div
    id="mobile-menu-wrapper" class="app-sidebar-wrapper" :class="{ 'app-sidebar-wrapper--active': visible }"
    @click="handleClick"
  >
    <div class="app-sidebar mobile-menu">
      <div class="full-width">
        <div class="row justify-end q-my-xs  q-gutter-sm">
          <jpoints-reward-info />
          <priority-fee />
          <select-language />
        </div>
        <h-c-navigation-tabs v-if="isHoldersClub" @close-menu="closeMenu" />
        <navigation-tabs v-else no-more-item @close-menu="closeMenu" />
        <holders-club-logo v-if="!isHoldersClub" dark-icon :blank="false" custom-class="mobile-menu--project-nav" @click="closeMenu" />
        <router-link v-else to="/" class="mobile-menu--project-nav" @click="closeMenu">
          <img :src="jpoolLogoDark" alt="jpool logo">
        </router-link>
      </div>
    </div>
  </div>
</template>
