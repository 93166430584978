<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts">
import type { StakeAccount } from '~/store'

export default defineComponent({
  emits: [
    'beforeDeposit',
    'afterDeposit',
    'beforeDeactivate',
    'afterDeactivate',
    'beforeWithdraw',
    'afterWithdraw',
  ],
  setup(_props, { emit }) {
    const stakeAccounts = useStakeAccountStore()
    const loading = computed(() => stakeAccounts.loading)
    const loadingPubkeys = computed(() => stakeAccounts.loadingPubkeys)

    return {
      loading,
      loadingPubkeys,

      accounts: computed(() => {
        return stakeAccounts.accountsFull
      }),

      deposit: async ({ stakeAccount, directTarget }: { stakeAccount: StakeAccount, directTarget?: string }) => {
        emit('beforeDeposit')
        await stakeAccounts.depositJpool([stakeAccount], directTarget)
        Analytics.track({ event: 'Deposit JSOL from sidebar', action: 'deposit', label: 'Deposit JSOL from sidebar' })

        emit('afterDeposit')
      },

      deactivate: async (address: string) => {
        emit('beforeDeactivate')
        await stakeAccounts.deactivate([address])
        Analytics.track({ event: 'Deactivate stake account from sidebar', action: 'deactivate', label: 'Deactivate stake account from sidebar' })
        emit('afterDeactivate')
      },

      withdraw: async (address: string, lamports: number) => {
        emit('beforeWithdraw')
        await stakeAccounts.withdraw([{ address, lamports }])
        Analytics.track({ event: 'Withdraw JSOL from sidebar', action: 'unstake', label: 'Withdraw JSOL from sidebar' })
        emit('afterWithdraw')
      },
    }
  },
})
</script>

<template>
  <q-card-section v-if="loading" class="flex flex-center text-primary" style="padding: 24px;">
    <q-skeleton height="20px" width="100%" class="q-mb-sm" />
    <q-skeleton height="20px" width="100%" />
  </q-card-section>

  <template v-else>
    <q-list v-if="accounts.length > 0" class="stake-accounts-list">
      <stake-account-item
        v-for="acc in accounts" :key="acc.stakeAccount.pubkey.toBase58()"
        :stake-account="acc.stakeAccount"
        :status="acc.state"
        :loading="loadingPubkeys.has(acc.stakeAccount.pubkey.toBase58())"
        @deactivate="deactivate"
        @withdraw="withdraw"
        @deposit="deposit"
      />
    </q-list>
    <div v-else class="flex flex-center q-pa-lg text-primary">
      <div v-html="$t('sidebar.noAccounts')" />
    </div>
  </template>
</template>
