<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import Cookies from 'js-cookie'
import { DOMAIN } from '../index'

const cookieName = 'cookie-accepted'
const isAccepted = ref(Cookies.get(cookieName) === 'true')

function hide() {
  Cookies.set(cookieName, 'true', { domain: DOMAIN, expires: 365 })
  isAccepted.value = true
}
</script>

<template>
  <q-dialog :model-value="!isAccepted" persistent position="bottom" @hide="hide">
    <q-card class="q-pa-sm">
      <q-card-section class="q-pb-none">
        {{ $t('other.cookie') }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="More info"
          color="accent"
          target="_blank"
          type="a"
          href="https://policies.google.com/technologies/cookies"
        />
        <q-btn v-close-popup flat label="Accept" color="accent" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
