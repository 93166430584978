<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { evaRefresh } from '@quasar/extras/eva-icons'
import { useWallet } from 'solana-wallets-vue'
import solIcon from '~/assets/img/icon/sol.svg'
import { JSOL_LOGO } from '~/config'
import { formatMoney, longPriceFormatter } from '~/utils'

defineProps({
  connectWalletClass: {
    type: String,
    required: false,
  },
})

const stakePoolStore = useStakePoolStore()
const balanceStore = useBalanceStore()
const stakeAccountStore = useStakeAccountStore()
const coinRateStore = useCoinRateStore()

const solBalance = computed(() => balanceStore.solBalance)
const tokenBalance = computed(() => balanceStore.tokenBalance)
const stakeSolBalance = computed(() => stakeAccountStore.stakeSolBalance)
const stakeLoading = computed(() => stakeAccountStore.loading)

const solUsd = computed(() => coinRateStore.solana.price * solBalance.value)
const jsolUsd = computed(
  () => (coinRateStore.solana.price * tokenBalance.value) / stakePoolStore.exchangeRate,
)
const stakedUsd = computed(() => coinRateStore.solana.price * stakeSolBalance.value)

const wallet = useWallet()
const { connected } = wallet

const formatPrice = (v: number) => longPriceFormatter.format(v)

const totalUsd = computed(() => solUsd.value + jsolUsd.value + stakedUsd.value)

const walletAssets = computed(() => [
  {
    usd: formatMoney(solUsd.value),
    token: formatPrice(solBalance.value),
    icon: solIcon,
    tokenName: 'SOL',
  },
  {
    usd: formatMoney(jsolUsd.value),
    token: formatPrice(tokenBalance.value),
    icon: JSOL_LOGO,
    tokenName: 'JSOL',
  },
])
</script>

<template>
  <q-card v-if="connected" class="wallet-balance" square>
    <q-card-section class="wallet-balance__head">
      <div>${{ formatMoney(totalUsd) }}</div>
      <div>{{ $t('common.wallet') }}</div>
    </q-card-section>

    <q-card-section class="wallet-balance__body">
      <q-list dense separator>
        <q-item v-for="row in walletAssets" :key="row.tokenName" class="wallet-balance__item">
          <q-item-section class="balance__value balance__value--usd">
            ${{ row.usd }}
          </q-item-section>
          <q-item-section class="balance__value balance__value--token">
            {{ row.token }}
          </q-item-section>
          <q-item-section side class="balance__value__logo">
            <q-item-label>
              <i-app-sol v-if="row.tokenName === 'SOL'" class="text-positive" />
              <img v-else-if="row.icon" :src="row.icon" :alt="row.tokenName">
              <!-- <component :is="row.component" v-if="row.component" class="text-positive" /> -->
              <span>{{ row.tokenName }}</span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

      <div class="wallet-balance__staking__title row">
        <div class="col-sm-4 col-xs text-left">
          {{ $t('other.myStaked') }} SOL
        </div>

        <q-btn class="wallet-balance__staking__refresh-btn q-ml-auto" unelevated color="primary" @click="stakeAccountStore.load">
          <q-icon size="28px" :name="evaRefresh" color="secondary" />
        </q-btn>

        <div class="col-xs col-sm-5">
          <span v-show="!stakeLoading">{{ formatPrice(stakeSolBalance) }} SOL</span>
        </div>
      </div>

      <sidebar-stake-accounts />

      <sidebar-direct-stakes-title />

      <sidebar-direct-stakes />
    </q-card-section>
  </q-card>
</template>
