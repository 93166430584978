<script lang="ts" setup>
import { matKeyboardArrowUp } from '@quasar/extras/material-icons'
import { debounce } from 'lodash-es'
import { scroll } from 'quasar'

const thresholdPosition = 100
const scrollDuration = 200

const visible = ref(false)

function onScroll(position: number) {
  visible.value = position > thresholdPosition
}

function scrollTo() {
  scroll.setVerticalScrollPosition(window, 0, scrollDuration)
}
</script>

<template>
  <q-btn
    v-scroll="debounce(onScroll, 50)"
    :icon="matKeyboardArrowUp"
    unelevated
    dense
    class="to-top-btn"
    :class="{ 'to-top-btn--active': visible }"
    @click="scrollTo"
  />
</template>

<style lang="scss" scoped>
.to-top-btn {
  position: sticky;
  left: 100vw;
  bottom: 40px;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: $border-radius;
  background-color: $gray-4;
  color: $primary;
  opacity: 0;
  z-index: 111;
  transition: opacity 0.1s ease-in-out;

  &--active {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: $breakpoint-xs) {
    right: 14px;
    bottom: 20px;
  }
}

body.body--dark {
  .to-top-btn {
    color: $primary;
  }
}
</style>
