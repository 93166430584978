<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
defineProps({
  noMoreItem: Boolean,
})

const emit = defineEmits(['closeMenu'])

const { t } = useTranslation()

export type Route = {
  name: string
  isLink?: boolean
  route: string
  // for submenu
  newSection?: boolean
  icon?: string
}

  type NavItem = {
    name: string
    isLink?: boolean
    isDisabled?: boolean
    route?: string
    routes?: Route[]
  }

const routes = computed<NavItem[]>(() => {
  return [
    {
      route: '/holders-club/rewards',
      name: t('hc.tabs.rewards'),
    },
    {
      route: '/holders-club/privileges',
      name: t('hc.tabs.privileges'),
    },
    {
      route: '/holders-club/about',
      name: t('hc.tabs.about'),
    },
  ]
})

function closeMenu() {
  emit('closeMenu')
}
</script>

<template>
  <div class="navigation-tabs">
    <template v-for="navItem in routes" :key="navItem.name">
      <navigation-link :page="(navItem as Route)" :class="$style.tabs" @close-menu="closeMenu" />
    </template>
  </div>
</template>

<style lang="scss" module>
.tabs {
  max-width: 170px;

  @media (max-width: $breakpoint-md) {
    max-width: 100%;

    & > span {
      border-left: none !important;
    }
  }
}
</style>
