<!--
  - This file is part of the Web3 Library developed by mFactory GmbH.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
const directStakeStore = useDirectStakeStore()
const stakes = computed(() => directStakeStore.directStakes)
const loading = computed(() => directStakeStore.loading)
const activeStakeId = ref(0)

const { withdrawing, setAmount, withdraw, useWithdrawSol } = useWithdraw()

async function withdrawStake(props: { stakeId: number, forceDelayed: boolean }) {
  try {
    activeStakeId.value = props.stakeId
    await withdraw(props.forceDelayed, props.stakeId)
  } catch (e) {
    console.error(e)
  } finally {
    activeStakeId.value = 0
  }
}
</script>

<template>
  <q-card-section v-if="loading" class="flex flex-center text-primary" style="padding: 24px;">
    <q-skeleton height="20px" width="100%" class="q-mb-sm" />
    <q-skeleton height="20px" width="100%" />
  </q-card-section>

  <q-list v-else-if="stakes.length > 0" class="stake-accounts-list">
    <sidebar-direct-stake
      v-for="acc in stakes" :key="acc.id"
      :stake="acc"
      :withdrawing="withdrawing"
      :instant="useWithdrawSol"
      :loading="activeStakeId === acc.id"
      @withdraw="withdrawStake"
      @set-amount="setAmount"
    />
  </q-list>
  <div v-else class="flex flex-center q-pa-lg text-primary">
    <div v-html="$t('sidebar.noDirectStake')" />
  </div>
</template>
