<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useRoiCalculator } from '~/features/roi-calculator'
import { isInvalidTime } from '~/utils'

export default defineComponent({
  setup() {
    const { investmentTime, investmentPeriod } = useRoiCalculator()

    const { t } = useTranslation()

    function localesPeriod(period: string) {
      switch (period) {
        case 'Year': return t('roi.period.year')
        case 'Month': return t('roi.period.month')
        case 'Epoch': return t('roi.period.epoch')
      }
    }

    watch(investmentTime, (val) => {
      if (val && Number(val) < 0) {
        investmentTime.value = '0'
      }
    })
    return {
      localesPeriod,
      investmentTime,
      investmentPeriod,
      isInvalid: computed(
        () =>
          investmentTime.value
          && (isInvalidTime(String(investmentTime.value), investmentPeriod.value)
            || Number(investmentTime.value) < 0),
      ),
    }
  },
  data() {
    return {
      periods: ['Year', 'Month', 'Epoch'],
    }
  },
})
</script>

<template>
  <div class="sol-calculator__form__group">
    <div class="sol-calculator__form__label">
      {{ $t('roi.investmentTenure') }}
    </div>
    <div class="sol-calculator__form__input-block">
      <input
        v-model="investmentTime"
        type="number"
        class="sol-calculator__form__input"
        :class="{ 'sol-calculator__form__input--invalid': isInvalid }"
      >
      <select v-model="investmentPeriod" class="sol-calculator__form__input" name="period">
        <option v-for="period in periods" :key="period" :value="period">
          {{ localesPeriod(period) }}
        </option>
      </select>
    </div>
  </div>
</template>
