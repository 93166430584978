<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script setup lang="ts">
import { useWallet } from 'solana-wallets-vue'
import { shortenAddress } from '~/utils'

const wallet = useWallet()
const { connected } = wallet
const walletAddress = computed(() => wallet.publicKey.value?.toBase58() ?? '')
const walletShortAddress = computed(() => shortenAddress(walletAddress.value, 14))
const walletShortAddressMob = computed(() => shortenAddress(walletAddress.value, 11))
</script>

<template>
  <div v-if="connected" class="wallet-address">
    <div class="wallet-address__label">
      {{ $t('common.address') }}
    </div>
    <div class="wallet-address__value">
      <span class="wallet-address__address">{{ $q.screen.gt.xs ? walletShortAddress : walletShortAddressMob }}</span>
      <copy-to-clipboard anchor="top left" self="bottom left" class="wallet-address__copy-btn" :text="walletAddress" />
    </div>
  </div>
</template>
