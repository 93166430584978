<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { matCalculate } from '@quasar/extras/material-icons'
import RoiBody from './Body.vue'
import RoiHeader from './Header.vue'
import '../assets/style.scss'

const roiDialog = ref(false)
const emitter = useEmitter()

emitter.on('closeCalculator', () => {
  roiDialog.value = false
})

function roiHandler() {
  roiDialog.value = !roiDialog.value
  Analytics.track({ event: 'click', action: 'roiHandler', label: 'Open roi calculator' })
}
</script>

<template>
  <q-btn
    v-bind="$attrs"
    class="sol-calculator-btn row"
    color="main-gray"
    text-color="white"
    size="md"
    :ripple="false"
    @click="roiHandler"
  >
    <q-icon size="42px" :name="matCalculate" color="white" />
    <span class="q-mx-auto">{{ $t('roi.title') }}</span>
  </q-btn>
  <q-dialog
    v-model="roiDialog"
    class="sol-calculator"
    transition-duration="150"
    transition-show="fade"
    transition-hide="fade"
  >
    <div class="sol-calculator__main">
      <roi-header />
      <roi-body />
    </div>
  </q-dialog>
</template>
