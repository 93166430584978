<!--
  - This file is part of the Web3 Library developed by mFactory GmbH.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { type DirectStake, formatAmount, lamportsToSol, shortenAddress } from '~/utils'

const props = defineProps({
  stake: {
    type: Object as PropType<DirectStake>,
    required: true,
  },
  instant: Boolean,
  withdrawing: Boolean,
  loading: Boolean,
})

defineEmits(['withdraw', 'setAmount'])

const validatorStore = useValidatorsStore()

const amount = computed(() => formatAmount(lamportsToSol(+props.stake.availableAmount)))
const name = computed(() => {
  const validator = validatorStore.allValidators.find(v => v.voteId === props.stake.voteId)
  return validator?.name || `${shortenAddress(props.stake.voteId ?? '')}`
})
</script>

<template>
  <div class="stake-account-wrapper">
    <div class="stake-account">
      <router-link :to="`/flex-staking/${stake.voteId}`" class="sidebar-direct__validator-name q-mr-xs">
        {{ name }}
        <q-tooltip anchor="bottom middle" class="text-body2 break-words">
          {{ name }}
        </q-tooltip>
      </router-link>

      <div class="stake-account__actions q-mr-sm">
        <q-btn
          class="q-ml-auto"
          dense
          color="primary"
          unelevated
          :disabled="withdrawing"
          @click="$emit('setAmount', lamportsToSol(+stake.availableAmount))"
        >
          <i-app-withdraw width="16px" height="16px" style="margin-left: 1px;" />
          <q-tooltip anchor="top middle" :offset="[0, 30]" class="text-body2">
            {{ $t('sidebar.withdraw') }}
          </q-tooltip>
          <q-menu>
            <q-list>
              <q-item v-show="instant" v-close-popup clickable @click="$emit('withdraw', { stakeId: stake.id, forceDelayed: false })">
                <q-item-section>{{ $t('stakeBox.action.unstakeNow') }}</q-item-section>
              </q-item>
              <q-item v-close-popup clickable @click="$emit('withdraw', { stakeId: stake.id, forceDelayed: true })">
                <q-item-section>{{ $t('stakeBox.action.unstakeDelayed') }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>

      <div class="stake-account__balance q-pr-xs">
        <div class="stake-account__balance__sol">
          {{ amount }} JSOL
        </div>
      </div>
    </div>

    <q-inner-loading :showing="loading">
      <q-spinner color="primary" />
    </q-inner-loading>
  </div>
</template>
